import * as React from 'react';
import {
  Button,
  makeStyles,
  Typography,
  MuiThemeProvider,
  useTheme,
  CustomTheme,
} from '@material-ui/core';
import nl2br from 'nl2br';
import {
  ChoiceButtonOnClickProps,
  ChoiceButtonProps,
  SingleChoiceProps,
} from './ElementTypeInterfaces';
import { BrandsEnum } from '../surveyCollector/SurveyCollector';

const useStyles = makeStyles((theme: CustomTheme) => ({
  squareButton: {
    borderRadius: theme.brandCode === BrandsEnum.SKODA ? '50px' : 0,
    margin: `${theme.spacing()}px 0`,
    textTransform: 'none',
    border: theme.brandCode === BrandsEnum.SKODA ? '2px solid' : 'none',
    backgroundColor:
      theme.brandCode === BrandsEnum.SKODA
        ? '#d5d5d5'
        : `${theme.palette.primary.main}`,
    color: theme.brandCode === BrandsEnum.SKODA ? 'black' : 'white',

    '&:hover': {
      backgroundColor:
        theme.brandCode === BrandsEnum.SKODA
          ? '#d5d5d5'
          : `${theme.palette.primary.main}`,
      color: theme.brandCode === BrandsEnum.SKODA ? 'black' : 'white',
    },
  },
  buttonSkoda: {
    borderRadius: theme.brandCode === BrandsEnum.SKODA ? '50px' : 0,
    margin: `${theme.spacing()}px 0`,
    textTransform: 'none',
    border: theme.brandCode === BrandsEnum.SKODA ? '2px solid' : 'none',
    backgroundColor: '#ffffff',
  },
  buttonDefault: {
    borderRadius: theme.brandCode === BrandsEnum.SKODA ? '50px' : 0,
    margin: `${theme.spacing()}px 0`,
    textTransform: 'none',
    border: theme.brandCode === BrandsEnum.SKODA ? '2px solid' : 'none',
    backgroundColor: 'default',
  },
  root: {
    width: '100%',
    maxWidth: 800,
  },
}));

const ChoiceButton: React.FunctionComponent<
  ChoiceButtonOnClickProps & ChoiceButtonProps
> = (props) => {
  const classes = useStyles();

  const onChange = () => {
    props.onChange(props.choice.value);
  };
  const theme = useTheme();
  return (
    <MuiThemeProvider
      theme={{
        ...theme,
      }}>
      <Button
        data-test-id={`ChoiceButton ${props.choice.value}`}
        fullWidth
        key={props.choice.value}
        onClick={onChange}
        variant="contained"
        className={
          props.selected
            ? classes.squareButton
            : theme.brandCode === BrandsEnum.SKODA
            ? classes.buttonSkoda
            : classes.buttonDefault
        }>
        <span dangerouslySetInnerHTML={{ __html: nl2br(props.choice.text) }} />
      </Button>
    </MuiThemeProvider>
  );
};

const SingleChoice: React.FunctionComponent<SingleChoiceProps> = ({
  choices,
  onChange,
  value,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {choices.map((choice) => (
        <ChoiceButton
          choice={choice}
          selected={choice.value === value}
          key={choice.value}
          onChange={onChange}
        />
      ))}
    </div>
  );
};

export default SingleChoice;
