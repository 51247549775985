import { red, grey } from '@material-ui/core/colors';
import { createMuiTheme, Theme as MUITheme } from '@material-ui/core';
import personalAgreementBg from '../static/images/personal-agreement-bg.png';
import { BrandsEnum } from '../surveyCollector/SurveyCollector';

const mainColor = '#F50537';

const { spacing } = createMuiTheme();

const myRed = {
  ...red,
  '500': mainColor,
  '600': mainColor,
  A100: mainColor,
  A200: mainColor,
  A400: mainColor,
  A700: mainColor,
};

const fontFamily = '"AudiType", "Helvetica Neue", Helvetica, Arial, sans-serif';

const theme = {
  palette: {
    primary: myRed,
    secondary: {
      light: grey.A100,
      main: '#000000',
      dark: grey.A400,
      contrastText: '#ffffff',
    },
    background: {
      default: 'ffffff',
    },
  },
  typography: {
    fontFamily: fontFamily,
    useNextVariants: true,
  },
  screenElement: {
    sidePadding: spacing(3),
    sidePaddingXs: spacing(),
  },
  images: {
    introDefaultImagePath: 'images/audi/introCar.png',
    progressImagePath: 'images/audi/car.jpg',
    logo: 'images/audi/logo.png',
    favicon: 'favicons/audi.png',
  },
  personalAgreement: {
    backgroundImagePath: personalAgreementBg,
    continueButtonColor: mainColor,
  },
  title: 'Audi',
  brandCode: BrandsEnum.AUDI,
};

export type AudiTheme = typeof theme & MUITheme;

export default theme;
