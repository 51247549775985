import * as React from 'react';
import { EscapeQuestionBasicProps } from './ElementTypeInterfaces';
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Typography,
  MuiThemeProvider,
  useTheme,
  CustomTheme,
} from '@material-ui/core';
import nl2br from 'nl2br';
import { BrandsEnum } from '../surveyCollector/SurveyCollector';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    root: {
      width: '100%',
    },
    thanksTextBox: {
      backgroundColor:
        theme.brandCode === BrandsEnum.SKODA
          ? '#ffffff'
          : theme.palette.primary.main,
      padding: theme.brandCode === BrandsEnum.SKODA ? 0 : theme.spacing(3),
      flexGrow: 1,
    },
    thanksText: {
      color: theme.brandCode === BrandsEnum.SKODA ? 'black' : '#ffffff',
      padding: theme.brandCode === BrandsEnum.SKODA ? 0 : theme.spacing(3),
      fontSize: theme.brandCode === BrandsEnum.SKODA ? '2.125rem' : 'auto',
      fontWeight: theme.brandCode === BrandsEnum.SKODA ? 'bold' : undefined,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    continueTextBox: {
      display: 'flex',
      flexDirection: 'column',
      borderColor: theme.palette.primary.main,
      borderWidth: theme.brandCode === BrandsEnum.SKODA ? '0px' : '3px',
      borderStyle: theme.brandCode === BrandsEnum.SKODA ? 'none' : 'solid',
      color: '#ffffff',
      marginTop:
        theme.brandCode === BrandsEnum.SKODA ? '0px' : theme.spacing(2),
      flexGrow: 1,
    },
    text: {
      margin: theme.brandCode === BrandsEnum.SKODA ? 0 : theme.spacing(3),
      fontSize: theme.brandCode === BrandsEnum.SKODA ? '1.625rem' : 'auto',
      fontWeight: theme.brandCode === BrandsEnum.SKODA ? 'bold' : undefined,
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    terminateTextBox: {
      display: 'flex',
      flexDirection: 'column',
      borderColor: '#CCCCCC',
      borderWidth: theme.brandCode === BrandsEnum.SKODA ? '0px' : '3px',
      borderStyle: theme.brandCode === BrandsEnum.SKODA ? 'none' : 'solid',
      color: theme.brandCode === BrandsEnum.SKODA ? 'black' : '#ffffff',
      marginTop: theme.brandCode === BrandsEnum.SKODA ? 0 : theme.spacing(2),
    },
    terminateText: {
      color: theme.brandCode === BrandsEnum.SKODA ? 'white' : '#CCCCCC',
      margin: theme.brandCode === BrandsEnum.SKODA ? 0 : theme.spacing(3),
      fontSize: theme.brandCode === BrandsEnum.SKODA ? '1.125rem' : 'auto',
      marginTop: theme.brandCode === BrandsEnum.SKODA ? '20px' : 'auto',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    terminateButton: {
      backgroundColor:
        theme.brandCode === BrandsEnum.SKODA ? '#ffffff' : '#CCCCCC',
      borderRadius: theme.brandCode === BrandsEnum.SKODA ? '50px' : '0px',
      borderStyle: theme.brandCode === BrandsEnum.SKODA ? 'solid' : 'none',
      borderWidth: theme.brandCode === BrandsEnum.SKODA ? '2px' : '0px',
      borderColor: theme.brandCode === BrandsEnum.SKODA ? '#C4C6C7' : '#ffffff',
      color: theme.brandCode === BrandsEnum.SKODA ? '#C4C6C7' : 'inherit',
      margin: 0,
      boxShadow: 'none',
      textTransform: theme.brandCode === BrandsEnum.SKODA ? 'none' : undefined,
      height: theme.brandCode === BrandsEnum.SKODA ? '44px' : 'auto',

      '&:hover': {
        backgroundColor:
          theme.brandCode === BrandsEnum.SKODA ? '#f1f1f1' : '#BBBBBB',
      },
      [theme.breakpoints.up('md')]: {
        minWidth: theme.brandCode === BrandsEnum.SKODA ? '512px' : '150px',
      },
      [theme.breakpoints.only('md')]: {
        minWidth:
          theme.brandCode === BrandsEnum.SKODA
            ? '-webkit-fill-available'
            : '150px',
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        width: theme.brandCode === BrandsEnum.SKODA ? '99%' : '150px',
      },
    },
    saveButton: {
      backgroundColor:
        theme.brandCode === BrandsEnum.SKODA ? '#464748' : '#CCCCCC',
      borderRadius: theme.brandCode === BrandsEnum.SKODA ? '50px' : '0px',
      color: theme.brandCode === BrandsEnum.SKODA ? '#ffffff' : 'inherit',
      margin: 0,
      boxShadow: 'none',
      textTransform: theme.brandCode === BrandsEnum.SKODA ? 'none' : undefined,
      height: theme.brandCode === BrandsEnum.SKODA ? '44px' : 'auto',
      marginBottom:
        theme.brandCode === BrandsEnum.SKODA ? theme.spacing() : 'auto',
      '&:hover': {
        backgroundColor:
          theme.brandCode === BrandsEnum.SKODA ? '#595a5b' : '#BBBBBB',
      },
      [theme.breakpoints.up('md')]: {
        minWidth: theme.brandCode === BrandsEnum.SKODA ? '512px' : '150px',
      },
      [theme.breakpoints.only('md')]: {
        minWidth:
          theme.brandCode === BrandsEnum.SKODA
            ? '-webkit-fill-available'
            : '150px',
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        width: theme.brandCode === BrandsEnum.SKODA ? '99%' : '150px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      buttonContainer: {
        flexDirection: 'column',
      },
      thanksTextBox: {
        padding: theme.spacing(2),
      },
      thanksText: {
        padding: 0,
        fontSize: theme.brandCode === BrandsEnum.SKODA ? '2rem' : '1.5rem',
      },
      saveButton: {
        marginBottom:
          theme.brandCode === BrandsEnum.SKODA
            ? theme.spacing(1)
            : theme.spacing(2),
        minHeight: 48,
      },
      squareButton: {
        minHeight: 48,
      },
      terminateButton: {
        minHeight: 48,
      },
      text: {
        margin: 0,
        padding: theme.spacing(2),
        fontSize: '1.5rem',
        flexGrow: 1,
      },
      terminateText: {
        color: '#CCCCCC',
        margin: 0,
        padding: theme.spacing(2),
        fontSize: '1.5rem',
      },
    },
    squareButton: {
      borderRadius: theme.brandCode === BrandsEnum.SKODA ? '50px' : '0px',
      margin: 0,
      boxShadow: 'none',
      textTransform: theme.brandCode === BrandsEnum.SKODA ? 'none' : undefined,
      height: theme.brandCode === BrandsEnum.SKODA ? '44px' : 'auto',
      marginTop: theme.brandCode === BrandsEnum.SKODA ? '60px' : 'auto',
      [theme.breakpoints.up('md')]: {
        minWidth: theme.brandCode === BrandsEnum.SKODA ? '512px' : '150px',
        marginBottom:
          theme.brandCode === BrandsEnum.SKODA ? theme.spacing(1) : 'auto',
      },
      [theme.breakpoints.only('md')]: {
        minWidth:
          theme.brandCode === BrandsEnum.SKODA
            ? '-webkit-fill-available'
            : '150px',
        marginBottom:
          theme.brandCode === BrandsEnum.SKODA ? theme.spacing(1) : 'auto',
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        width: theme.brandCode === BrandsEnum.SKODA ? '99%' : '150px',
        marginBottom:
          theme.brandCode === BrandsEnum.SKODA ? theme.spacing(1) : 'auto',
      },
    },
    filler: {
      flexGrow: 1,
    },
    fillerSa: {
      padding: '14px',
    },
  })
);

const EscapeQuestionBasic: React.FunctionComponent<EscapeQuestionBasicProps> = (
  props
) => {
  const {
    text,
    thanksMessage,
    onChange,
    continueButtonText = 'Next question »',
    terminateText,
    terminateButtonText = 'Finish',
    saveButtonText = 'Save and continue later',
  } = props;

  const classes = useStyles();
  // text is deprecated, use the continueText
  const continueText = props.continueText || text;

  const onContinueClick = () => onChange(1);
  const onSaveClick = () => onChange(2);
  const onTerminateClick = () => onChange(0);
  const theme = useTheme();
  return (
    <MuiThemeProvider
      theme={{
        ...theme,
      }}>
      {theme.brandCode === BrandsEnum.SKODA ? (
        <div className={classes.root}>
          <Grid container justify="flex-start">
            <Grid item className={classes.thanksTextBox}>
              <Typography
                className={classes.thanksText}
                color="textPrimary"
                align="left"
                dangerouslySetInnerHTML={{ __html: nl2br(thanksMessage) }}
              />
            </Grid>

            <Grid item className={classes.continueTextBox}>
              <Typography
                className={classes.text}
                color="textPrimary"
                align="left"
                dangerouslySetInnerHTML={{ __html: nl2br(continueText) }}
              />
            </Grid>

            {terminateText && ( // @TODO remove the terminate text on SKODA brand
              <Grid item className={classes.terminateTextBox}>
                <Typography
                  className={classes.terminateText}
                  variant="h5"
                  color="secondary"
                  align="left"
                  dangerouslySetInnerHTML={{ __html: nl2br(terminateText) }}
                />
                <Grid justify="flex-start" className={classes.buttonContainer}>
                  <Button
                    data-test-id="continueButton"
                    onClick={onContinueClick}
                    size="large"
                    color="primary"
                    className={classes.squareButton}
                    variant="contained">
                    <Typography variant="h6" color="inherit">
                      {continueButtonText}
                    </Typography>
                  </Button>
                  <Button
                    data-test-id="saveButton"
                    onClick={onSaveClick}
                    size="large"
                    color="secondary"
                    className={classes.saveButton}
                    variant="contained">
                    <Typography variant="h6" color="inherit">
                      {saveButtonText}
                    </Typography>
                  </Button>
                  <Button
                    data-test-id="terminateButton"
                    onClick={onTerminateClick}
                    size="large"
                    color="secondary"
                    className={classes.terminateButton}
                    variant="contained">
                    <Typography variant="h6" color="inherit">
                      {terminateButtonText}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>
      ) : (
        <div className={classes.root}>
          <Grid container justify="center">
            <Grid item xs={12} sm={10} md={8} className={classes.thanksTextBox}>
              <Typography
                className={classes.thanksText}
                variant="h4"
                color="textPrimary"
                align="center"
                dangerouslySetInnerHTML={{ __html: nl2br(thanksMessage) }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={10}
              md={8}
              className={classes.continueTextBox}>
              <Typography
                className={classes.text}
                variant="h4"
                color="primary"
                align="center"
                dangerouslySetInnerHTML={{ __html: nl2br(continueText) }}
              />
              <Grid
                container
                justify="flex-end"
                className={classes.buttonContainer}>
                <Button
                  data-test-id="continueButton"
                  onClick={onContinueClick}
                  size="large"
                  color="primary"
                  className={classes.squareButton}
                  variant="contained">
                  <Typography variant="h6" color="inherit">
                    {continueButtonText}
                  </Typography>
                </Button>
              </Grid>
            </Grid>

            {terminateText && (
              <Grid
                item
                xs={12}
                sm={10}
                md={8}
                className={classes.terminateTextBox}>
                <Typography
                  className={classes.terminateText}
                  variant="h4"
                  color="secondary"
                  align="center"
                  dangerouslySetInnerHTML={{ __html: nl2br(terminateText) }}
                />
                <Grid
                  container
                  justify="flex-end"
                  className={classes.buttonContainer}>
                  <Button
                    data-test-id="saveButton"
                    onClick={onSaveClick}
                    size="large"
                    color="secondary"
                    className={classes.saveButton}
                    variant="contained">
                    <Typography variant="h6" color="inherit">
                      {saveButtonText}
                    </Typography>
                  </Button>
                  <div className={classes.filler} />
                  <Button
                    data-test-id="terminateButton"
                    onClick={onTerminateClick}
                    size="large"
                    color="secondary"
                    className={classes.terminateButton}
                    variant="contained">
                    <Typography variant="h6" color="inherit">
                      {terminateButtonText}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </MuiThemeProvider>
  );
};

export default EscapeQuestionBasic;
