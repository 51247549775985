import { isEmptyArray } from 'ramda-adjunct';
import { useLayoutEffect, useState } from 'react';
import { ScreenElementInstance } from 'src/surveyCollector/SurveyCollector';

export const useWindowSize = () => {
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });

  useLayoutEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return size;
};

export const isEscapeScreen = (elements: ScreenElementInstance[]) => {
  return isEmptyArray(
    elements.filter(
      (element) =>
        element.type === 'escape' || element.type === 'personalAgreement'
    )
  );
};
