import {
  ScreenElement,
  ScreenElementInstance,
  ScreenMove,
  SurveyScreen,
  Values,
} from './SurveyCollector';
import {
  always,
  assocPath,
  complement,
  cond,
  has,
  identity,
  ifElse,
  is,
  isEmpty,
  lensProp,
  over,
  pathOr,
  pipe,
  prop,
  propOr,
  propSatisfies,
  when,
} from 'ramda';
import { isNotEmpty } from 'ramda-adjunct';
import {
  ExpressionFunction,
  expressionToFunction,
} from './expressionEvaluation';
import i18next from '../i18n/i18next';
import { generalTypeMapping } from '../elementTypes';

const updateValueInProps = (element: ScreenElementInstance, values = {}) => {
  return assocPath(['componentProps', 'value'], values[element.id], element);
};

const textToString = (element: ScreenElementInstance, values = {}) => {
  return over(
    lensProp('text'),
    when(is(Function), (textFunction) => textFunction(values)),
    element
  );
};
// @ts-ignore
const imagePathToString = (element: ScreenElementInstance, values = {}) =>
  assocPath(
    ['componentProps', 'imagePath'],
    ifElse(
      is(Function),
      (f) => f(values),
      (v) => v
      // @ts-ignore
    )(element.imagePath),
    element
  );

// @ts-ignore
const agreementInstructionToString = (
  element: ScreenElementInstance,
  values = {}
) => {
  return assocPath(
    ['componentProps', 'agreement', 'instruction'],
    // @ts-ignore
    ifElse(
      is(Function),
      (f) => f(values),
      (v) => v
    )(pathOr('', ['agreement', 'instruction'], element)),
    element
  );
};

// @ts-ignore
const googleReviewInstructionToString = (
  element: ScreenElementInstance,
  values = {}
) => {
  return assocPath(
    ['componentProps', 'instruction'],
    // @ts-ignore
    ifElse(
      is(Function),
      (f) => f(values),
      (v) => v
    )(pathOr('', ['instruction'], element)),
    element
  );
};

const choicesToString = (element: ScreenElementInstance, values = {}) => {
  if (element.choices) {
    const choices = element.choices.map(
      over(
        lensProp('text'),
        when(is(Function), (textFunction) => textFunction(values))
      )
    );

    return {
      ...element,
      componentProps: {
        ...element.componentProps,
        choices,
      },
      choices,
    };
  }
  return element;
};

const introductionToString = (element: ScreenElementInstance, values = {}) =>
  pipe(
    // @ts-expect-error check types and fix it
    prop('introduction'),
    when(is(Function), (textFunction) => textFunction(values)),
    (introductionText) =>
      assocPath(['componentProps', 'introduction'], introductionText, element)
    // @ts-expect-error check types and fix it
  )(element);

// TODO refactor similar code
const visibleIfHookFactory = (elementDefinition: ScreenElement) => {
  const visibleIfHook = expressionToFunction(
    elementDefinition.visibleIf as ExpressionFunction
  );
  return (element: ScreenElementInstance, values: Values) =>
    assocPath(['status', 'visible'], visibleIfHook(values), element);
};

const hideIfHookFactory = (elementDefinition: ScreenElement) => {
  const hideIfHook = expressionToFunction(
    elementDefinition.hideIf as ExpressionFunction
  );

  return (element: ScreenElementInstance, values: Values) =>
    assocPath(['status', 'visible'], !hideIfHook(values), element);
};

const isRequired = complement(has('optional'));

const isFilled = (element, values) =>
  has(element.id, values) && propSatisfies(isNotEmpty, element.id, values);

// TODO split validations from common hooks definitions
const getRequiredMessage = (element: ScreenElementInstance) => {
  // TODO temporarily solutions, this should solve model improvements (split component and type)
  const questionType = generalTypeMapping[element.type];
  return i18next.t(
    [`validation.required.${questionType}`, 'validation.required.general'],
    { defaultValue: 'Please answer this question' }
  );
};

const requiredValidator = (element: ScreenElementInstance, values: Values) =>
  assocPath(
    ['status', 'validationResult'],
    isFilled(element, values) ? [] : [{ message: getRequiredMessage(element) }],
    element
  );

const validationPreHooksFactory = (elementDefinition: ScreenElement) =>
  isRequired(elementDefinition) ? requiredValidator : identity;

const hasNoBackWhenExitOptions = pathOr(false, [
  'hookOptions',
  'noBackWhenExit',
]);

export const createPreHooks = (
  element: ScreenElement,
  variables: Values
): any[] => {
  const visibilityPreHookFactory = cond([
    [prop('visibleIf'), visibleIfHookFactory],
    [prop('hideIf'), hideIfHookFactory],
    [always(true), always(identity)],
  ]);

  return [
    textToString,
    introductionToString,
    imagePathToString,
    agreementInstructionToString,
    googleReviewInstructionToString,
    choicesToString,
    validationPreHooksFactory(element),
    visibilityPreHookFactory(element),
    updateValueInProps,
    when(
      (element) => hasNoBackWhenExitOptions(element) && !variables['QE'],
      (element) => assocPath(['status', 'disableGoBack'], true, element)
    ),
  ];
};

/* post hooks **************/

const escapeQuestionEvaluationHook = (
  element,
  value,
  move,
  { hookOptions }
) => {
  switch (value) {
    // we need show some other questions before end
    case 0:
      if (hookOptions.jumpScreenPath) {
        return {
          moveDirection: 'jump',
          jumpPath: hookOptions.jumpScreenPath,
          source: 'escapeQuestion',
        };
      }

      return {
        moveDirection: 'end',
        source: 'escapeQuestion',
      };

    case 1:
      return move;

    case 2:
      return {
        moveDirection: 'none',
        source: move.source,
        sendData: true,
      };

    default:
      return null;
  }
};

const escapeImmediatelyHook = () => ({
  moveDirection: 'end',
  source: 'hook',
  sendData: true,
});

const escapeQuestionDeferredEvaluationHook = (
  element,
  value,
  move,
  { hookOptions, collectedData }
) => {
  if (!collectedData['QE']) {
    return {
      moveDirection: 'end',
      source: 'hook',
      sendData: true,
    };
  }

  return move;
};

const getHookOptions = propOr({}, 'hookOptions');

/***
 * THIS part is ugly hack for https://gitlab.commity.cz/skoda/xperience/issues/228
 * and
 * https://gitlab.commity.cz/skoda/xperience/issues/229
 */

// TODO specify hooks signature, types,...
export const createPostHooks = (elementDefinition: ScreenElement): any[] => {
  const postHooks = [] as any[];

  if (
    elementDefinition.type === 'escape' ||
    elementDefinition.type === 'personalAgreement'
  ) {
    postHooks.push({
      hook: escapeQuestionEvaluationHook,
    });
  }

  const hookOptions = getHookOptions(elementDefinition);

  // @ts-ignore
  if (hookOptions.escapeQuestionEvaluation) {
    postHooks.push({
      hook: escapeQuestionDeferredEvaluationHook,
    });
  }

  // @ts-ignore
  if (hookOptions.escapeImmediately) {
    postHooks.push({
      hook: escapeImmediatelyHook,
    });
  }

  return postHooks;
};

const hasPostHooks = propSatisfies(complement(isEmpty), 'postHooks');

export const processPostHooks = (
  screen: SurveyScreen,
  move: ScreenMove,
  collectedData: Values
) => {
  // TODO consider how work with previous and processCollectedData
  if (screen.processCollectedData || move.moveDirection === 'previous') {
    return move;
  }

  const hookResults = screen.elements
    .filter(hasPostHooks)
    // TODO consider work with more posthooks and hook signature
    .map((element) => {
      const hook = element.postHooks[0].hook;
      return hook(element, screen.values[element.id], move, {
        hookOptions: getHookOptions(element),
        collectedData,
      });
    });

  if (isEmpty(hookResults)) {
    return move;
  }

  return hookResults[0];
};
