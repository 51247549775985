import * as React from 'react';
import {
  SurveyMetadata,
  SurveyScreen,
} from '../../surveyCollector/SurveyCollector';
import ScreenElement from '../components/ScreenElement';
import { assocPath, lensPath, over, pipe, when } from 'ramda';
import { onAutoNext } from '../../surveyCollector/screenStreams';
import {
  firstElementIsQuestionWithoutNextButton,
  shouldUseAutoNextDecorator,
} from '../../surveyCollector/screenProcessing';
import { autoNextEnabledForSurvey } from '../../surveyModel/flags';
import { isGender } from 'xperience-model-management';
import { makeStyles } from '@material-ui/core';
import { isEscapeScreen, useWindowSize } from '../utils/screen';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    paddingBottom: 25,

    [breakpoints.down('sm')]: {
      paddingBottom: 0,
    },

    '& > *': {
      marginBottom: spacing(2),
    },

    '& > :last-child': {
      paddingTop: 0,
      paddingBottom: 0,
      marginBottom: 0,
    },
  },
}));

export type SurveyScreenProps = {
  screen: SurveyScreen;
  surveyMetadata: SurveyMetadata;
};

const AUTO_NEXT_DELAY_IN_MS = 300;

const autoNextDecorator = (onChange) =>
  pipe(onChange, () => setTimeout(onAutoNext, AUTO_NEXT_DELAY_IN_MS));

const decorateOnChange = over(
  lensPath(['componentProps', 'onChange']),
  autoNextDecorator
);

export const ShowScreen: React.FunctionComponent<SurveyScreenProps> = ({
  screen,
  surveyMetadata,
}) => {
  const classes = useStyles();
  const screenSize = useWindowSize();

  const styleOverride = React.useMemo(
    () => ({
      paddingBottom:
        isEscapeScreen(screen.elements) && screenSize.width < 1280
          ? 125
          : undefined,
    }),
    [screen, screenSize]
  );

  React.useEffect(() => {
    const scrollContainer = document.querySelector('#screenQuestion');
    if (scrollContainer) {
      scrollContainer.scrollTop = 0;
    }
  }, []);
  // escape question nemá žádná tlačítka next/prev, proto potřebuje autonext vždy, ostatní jen pokud je povoleno pro survey
  const autoNextEnabled =
    firstElementIsQuestionWithoutNextButton(screen) ||
    (autoNextEnabledForSurvey(surveyMetadata) &&
      shouldUseAutoNextDecorator(screen));

  const screenElements = screen.elements
    .map(when(() => autoNextEnabled, decorateOnChange))
    .map(
      when(
        isGender,
        assocPath(
          ['componentProps', 'imageMapping'],
          surveyMetadata.genderImageMapping
        )
      )
    )
    .map((element) => (
      <ScreenElement
        key={element.id}
        {...element}
        showValidation={screen.status.displayValidationErrors}
        hideMainText={
          element.type === 'escape' || element.type === 'personalAgreement'
        }
      />
    ));

  return (
    <div className={classes.root} style={styleOverride}>
      {screenElements}
    </div>
  );
};
