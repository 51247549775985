import * as React from 'react';
import { CustomTheme, Grid, Theme, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import nl2br from 'nl2br';
import { elementMapping } from '../../elementTypes';
import ErrorContainer, { ValidationResult } from './ErrorContainer';
import classNames from 'classnames';
import { BrandsEnum } from '../../surveyCollector/SurveyCollector';

interface ThemeProps extends Theme {
  screenElement: {
    sidePadding: number;
    sidePaddingXs: number;
  };
}

const useStyles = makeStyles((theme: CustomTheme) => {
  // TODO - type for global theme definition
  const extendedTheme = theme as ThemeProps;

  return {
    root: {
      maxWidth: extendedTheme.breakpoints.values.md,
      margin: '0 auto',
      padding: `0 ${extendedTheme.screenElement.sidePadding}px`,
      [extendedTheme.breakpoints.down('xs')]: {
        padding: `0 ${extendedTheme.screenElement.sidePaddingXs}px`,
      },
      display: 'flex',
      flexDirection: 'column',
    },

    rootIE: {
      display: 'block',
    },
    question: {
      borderBottom: `${extendedTheme.spacing() / 2}px solid ${
        extendedTheme.palette.primary.main
      }`,
      paddingBottom: extendedTheme.spacing(),
      marginBottom: extendedTheme.spacing(),
      fontSize: '1.3rem',
      [extendedTheme.breakpoints.down('md')]: {
        fontSize: '1.1rem',
        borderBottomWidth: 3,
      },
      [extendedTheme.breakpoints.down('xs')]: {
        fontSize: '1rem',
      },
    },

    compactView: {
      borderBottom: 'unset',
      marginBottom: 0,
      paddingBottom: 0,
    },
    errors: {
      [theme.breakpoints.down('sm')]: {
        order: -1,
      },
    },

    pageTitle: {},
  };
});

export enum QUESTION_VISUAL_TYPE { // TODO move to better place
  NORMAL = 'normal',
  SECONDARY = 'secondary',
  COMPACT = 'compact',
}

export interface ScreenElementProps {
  id: string;
  type: string;
  text?: any; // TODO fix this
  instruction?: string;
  componentProps?: any;
  showValidation?: boolean;
  touched?: boolean;
  value?: any;
  questionVisualType?: QUESTION_VISUAL_TYPE;
  hideMainText?: boolean;
  status: {
    validationResult?: ValidationResult[];
  };
  placeId?: string;
}

const ScreenElement: React.FunctionComponent<ScreenElementProps> = ({
  componentProps,
  questionVisualType = QUESTION_VISUAL_TYPE.NORMAL,
  hideMainText,
  id,
  instruction,
  text,
  type,
  status,
  showValidation,
  touched,
  placeId,
  ...props
}) => {
  const classes = useStyles();
  const Component = elementMapping[type];
  const validationResult = status.validationResult || [];
  const isInvalid = validationResult.length > 0;
  const showInvalidMessage = isInvalid && (showValidation || touched);
  // @ts-ignore - unconventional IE detection
  const isIE = Boolean(window.document.documentMode);
  const isCompact = questionVisualType === QUESTION_VISUAL_TYPE.COMPACT;
  const hideInstruction = type === 'googleReview';

  const classesOverride = React.useMemo(
    () => ({
      question: {
        fontSize: '1.5rem',
      },
    }),
    [id]
  );

  return (
    <div
      className={classNames(classes.root, { [classes.rootIE]: isIE })}
      data-test-id={`ScreenElement ${id}`}>
      {text && !hideMainText ? (
        <Grid data-test-id="ScreenElementText" item xs={12}>
          <Typography
            align="left"
            variant="h5"
            className={classNames(
              id === 'intro' ? classesOverride.question : classes.question,
              isCompact && classes.compactView
            )}
            style={{
              fontSize: id.toLowerCase().includes('comment')
                ? '1rem'
                : undefined,
            }}
            dangerouslySetInnerHTML={{ __html: nl2br(text) }}
          />
        </Grid>
      ) : (
        ''
      )}
      {instruction && !hideInstruction ? (
        <Grid item xs={12}>
          <Typography
            align="left"
            style={{ display: 'block' }}
            variant="caption"
            color="inherit"
            dangerouslySetInnerHTML={{ __html: nl2br(instruction) }}
          />
        </Grid>
      ) : (
        ''
      )}
      <Grid container justify="center">
        <Component {...componentProps} text={text} error={showInvalidMessage} />
      </Grid>
      {showInvalidMessage ? (
        <ErrorContainer
          className={classes.errors}
          validationResult={validationResult}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default ScreenElement;
