/* tslint:disable */
import * as React from 'react';
import { useState } from 'react';
import {
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  makeStyles,
  Theme,
  Button,
  Typography,
} from '@material-ui/core';
import * as ScreenStreams from '../surveyCollector/screenStreams';
import { SingleChoiceProps } from './ElementTypeInterfaces';
import SingleChoice from './SingleChoice';
import * as striptags from 'striptags';

export interface GoogleReviewProps {
  text: string;
  placeId: string;
  instruction: string;
  googleSecondPhase: string;
  nextButtonOverride(func: Function): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  squareButton: {
    borderRadius: 0,
    margin: `${theme.spacing()}px 0`,
    textTransform: 'none',
  },
  root: {
    width: '100%',
    maxWidth: 800,
  },
  instruction: {
    fontSize: '0.85rem',
    textAlign: 'left',
    margin: '8px auto 8px 0px',

    '& a': {
      color: theme.palette.primary.main,
    },
  },
}));

const GoogleReview: React.FunctionComponent<
  GoogleReviewProps & SingleChoiceProps
> = (props) => {
  const styles = useStyles();
  const [selectedOption, setSelectedOption] = useState(0);
  const [nextScreen, setNextScreen] = useState(false);
  const [reviewShown, setReviewShown] = useState(false);

  const popupWindow = (
    url: string,
    windowName: string,
    win: Window & typeof globalThis,
    w: number,
    h: number
  ) => {
    if (!win.top) {
      return null;
    }
    const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
    const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
    return win.open(
      url,
      windowName,
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
    );
  };

  const goToReview = () => {
    if (popupWindow(reviewLink(), 'Review', window, 630, 600) == null) {
      return <p>Window popup failed!</p>;
    }
    setReviewShown(true);
    return null;
  };

  const localOnChange = (pickedValue: any) => {
    setSelectedOption(pickedValue);
    props.onChange(pickedValue);
  };

  const componentOnNextClick = () => {
    setNextScreen(true);
  };

  const returnFuncToParent = () => {
    if (nextScreen) {
      return ScreenStreams.onNextClick;
    }
    if (selectedOption != 999) {
      return componentOnNextClick;
    }
    return ScreenStreams.onNextClick;
  };

  const page1 = () => {
    const choiceValues = props.choices;
    return (
      <div className={styles.root}>
        <SingleChoice
          choices={choiceValues}
          onChange={localOnChange}
          value={selectedOption}
        />
      </div>
    );
  };

  const reviewLink = () => {
    return `https://search.google.com/local/writereview?placeid=${props.placeId}`;
  };

  const secondPhaseText = () => {
    if (!props.googleSecondPhase) {
      return <></>;
    }
    const match = props.googleSecondPhase.match(/\$\[([^)]+)\]/);
    const anchorText = match && match[1];
    if (!anchorText) {
      return <></>;
    }
    const screenText = props.googleSecondPhase.split(`\$\[${anchorText}\]`);
    return (
      <>
        <p>
          {screenText[0]}
          <a href={reviewLink()} target="_blank">
            {anchorText}
          </a>
          {screenText[1]}
        </p>
      </>
    );
  };

  const page2 = () => {
    return (
      <>
        {!reviewShown && goToReview()}
        {secondPhaseText()}
      </>
    );
  };

  React.useEffect(() => {
    props.nextButtonOverride(returnFuncToParent);
  }, [selectedOption, nextScreen]);

  return (
    <>
      {props.instruction && (
        <Typography variant="body1" className={styles.instruction}>
          {striptags(props.instruction)}
        </Typography>
      )}
      <div className={styles.root}>
        {!nextScreen && page1()}
        {nextScreen && page2()}
      </div>
    </>
  );
};

export default GoogleReview;
