import { green, grey } from '@material-ui/core/colors';
import { createMuiTheme, Theme as MUITheme } from '@material-ui/core';
import personalAgreementBg from '../static/images/skoda.png';
import personalThanksBg from '../static/images/skoda2.png';
import personalAgreementBgTriangleMoved from '../static/images/skoda3.png';
import { BrandsEnum } from '../surveyCollector/SurveyCollector';

const mainColor = '#78faae';
const emerald = '#0e3a2f';

const { spacing } = createMuiTheme();

const myGreen = {
  A800: '#0E3A2F',
  A600: '#419468',
  A500: '#59BC87',
  A200: '#ABFFCC',
  A300: '#78FAAE',
};

const fontFamily =
  '"SKODA Next", "Helvetica Neue", Helvetica, Arial, sans-serif';

const theme = {
  palette: {
    primary: {
      light: myGreen.A300,
      main: mainColor,
      dark: myGreen.A800,
      contrastText: '#000',
    },
    emerald: emerald,
    secondary: {
      light: grey.A100,
      main: grey.A200,
      dark: grey.A400,
      contrastText: '#ffffff',
    },
    background: {
      default: '#ffffff',
    },
  },
  typography: {
    fontFamily: fontFamily,
    useNextVariants: true,
  },
  screenElement: {
    sidePadding: spacing(),
    sidePaddingXs: spacing(),
  },
  images: {
    introDefaultImagePath: 'images/skoda/introCar.jpeg',
    progressImagePath: 'images/skoda/car.png',
    logo: 'images/skoda/logo.png',
    favicon: 'favicons/skoda.ico',
  },
  personalAgreement: {
    backgroundImagePath: personalAgreementBgTriangleMoved,
    continueButtonColor: mainColor,
  },
  thanks: {
    backgroundImagePath: personalThanksBg,
  },
  title: 'Skoda',
  brandCode: BrandsEnum.SKODA,
};

export type SkodaTheme = typeof theme & MUITheme;
export default theme;
